import React from "react";
import styles from "../style/About.module.css";
import Group2 from "../images/Group2.svg";
import Group1 from "../images/Group1.svg";

const About = () => {
  console.log(window.innerWidth, window.innerHeight);
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_head}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          เกี่ยวกับ
        </h1>
        <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
          คณะ
        </h1>
      </div>
      <div className={styles.btn_content}>
        <div
          className={styles.btn}
          onClick={() => {
            window.open("http://www.human.nu.ac.th/th/about/vision.php");
          }}
        >
          <div className={styles.icon_box}>
            <img src={Group1} className={styles.icon} alt="icon 1"/>
          </div>
          <div className={styles.text_btn_box}>
            <span className={styles.text1}>
              วิสัยทัศน์ พันธกิจ เป้าประสงค์ และกลยุทธ์การพัฒนาคณะมนุษยศาสตร์
              มหาวิทยาลัยนเรศวร
            </span>
          </div>
        </div>
        <div
          className={styles.btn}
          onClick={() => {
            window.open("http://www.human.nu.ac.th/th/about/committees.php");
          }}
        >
          <div className={styles.icon_box}>
            <img src={Group2} className={styles.icon} alt="icon 2"/>
          </div>
          <div className={styles.text_btn_box}>
            <span className={styles.text1}>ผู้บริหาร</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
