import React from "react";
import styles from "../../style/Setting.module.css";
import Export from "../../images/export.png";
import ios1 from "../../images/setting/ios/ios_1.jpg"
import ios2 from "../../images/setting/ios/ios_2.jpg"
import ios3 from "../../images/setting/ios/ios_3.jpg"
import ios4 from "../../images/setting/ios/ios_4.jpg"
import ios5 from "../../images/setting/ios/ios_5.jpg"
import ios6 from "../../images/setting/ios/ios_6.jpg"
import ios7 from "../../images/setting/ios/ios_7.jpg"
import ios8 from "../../images/setting/ios/ios_8.jpg"
import PDF from "../../files/IOS.pdf"

const IOS = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
      }}
    >
      <div className={styles.box_head}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          วิธีเปิดแจ้งเตือน
        </h1>
        <h1 className={styles.text_head} style={{ color: "#5271FF" }}>
          สำหรับ IOS
        </h1>
      </div>
      <div className={styles.view_alert}>
        <h1 className={styles.text_alert} style={{ color: "#E34545" }}>
          *สำหรับโทรศัพท์อัปเดต IOS ใหม่ หรือยังไม่เคยตั้งค่าเครื่อง
          ลองตั้งค่าตามขั้นตอนใหม่ด้วยเช่นกัน
        </h1>
      </div>

      <div className={styles.btn_view}>
        <div
          className={styles.export_btn}
          style={{ backgroundColor: "#5271FF" }}
          onClick={() => {window.open(PDF)}}
        >
          <div className={styles.inBtn_view} style={{ width: "15%" }}>
            <img src={Export} className={styles.icon} alt="icon 1" />
          </div>

          <div style={{ width: "85%" }}>
            <h1 className={styles.text_btn}>
              ดาวน์โหลดไฟล์วิธีเปิดแจ้งเตือน (PDF)
            </h1>
          </div>
        </div>
      </div>

      <img src={ios1} className={styles.image} alt="icon 2"/>
      <img src={ios2} className={styles.image} alt="icon 3"/>
      <img src={ios3} className={styles.image} alt="icon 4"/>
      <img src={ios4} className={styles.image} alt="icon 5"/>
      <img src={ios5} className={styles.image} alt="icon 6"/>
      <img src={ios6} className={styles.image} alt="icon 7"/>
      <img src={ios7} className={styles.image} alt="icon 8"/>
      <img src={ios8} className={styles.image} alt="icon 9"/>

    </div>
  );
};

export default IOS;
